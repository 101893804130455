'use client';

import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/cn';
import { forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Root } from '@radix-ui/react-label';

const labelVariants = cva(
    'text-sm font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
);

const Label = forwardRef<
    React.ElementRef<typeof Root>,
    React.ComponentPropsWithoutRef<typeof Root> & VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
    <Root ref={ref} className={cn(labelVariants(), className)} {...props} />
));
Label.displayName = Root.displayName;

export { Label };
