export default function MicrosoftIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2499.6 2500" {...props}>
            <path fill="#f1511b" d="M1187.9 1187.9H0V0h1187.9z" />
            <path fill="#80cc28" d="M2499.6 1187.9h-1188V0h1187.9v1187.9z" />
            <path fill="#00adef" d="M1187.9 2500H0V1312.1h1187.9z" />
            <path fill="#fbbc09" d="M2499.6 2500h-1188V1312.1h1187.9V2500z" />
        </svg>
    );
}
