import { cn } from '@/utils/cn';

interface PProps {
    children: React.ReactNode;
    settings?: React.HTMLAttributes<HTMLParagraphElement>;
    className?: string;
}

export function P({ children, settings, className }: PProps) {
    return (
        <p className={cn('leading-7 [&:not(:first-child)]:mt-6', className)} {...settings}>
            {children}
        </p>
    );
}
