import { cn } from '@/utils/cn';

interface LargeProps {
    children: React.ReactNode;
    settings?: React.HTMLAttributes<HTMLDivElement>;
    className?: string;
}

export function Large({ children, settings, className }: LargeProps) {
    return (
        <div className={cn('text-lg font-semibold', className)} {...settings}>
            {children}
        </div>
    );
}
